import {mapActions, mapGetters} from 'vuex'
import { doQuery, doAction } from "@/api/core"
import { startAI, callAI, endAI, tts } from "@/api/practice"
import util from '@/utils/cmyUtils.js'
import dateUtils from '@/utils/dateUtils.js'
import PracticeResultDrawer from '@/components/PracticeResultDrawer/PracticeResultDrawer.vue'

export default {
    name: "textPractice",
    data() {
        return {
            readonly: false,
            practice_result_mode: undefined,
            acct_id: undefined,

            //场景id，暂时固定
            scene_id: 'SCENE00000000000',
            //会话
            session_id: null,
            session_status: null,
            //对话记录
            chat_data: [],
            //说的话
            query: null,
            //评分记录
            scores: null,

            boAsr: false,
            //audio播放控制
            myAudio: null,
            curPlaySrc:null,
            curPlayQueue:[],
        }
    },
    computed: {
        ...mapGetters([
            'winSize',
            'staffId',
            'myInfo'
        ])
    },
    components: {
        PracticeResultDrawer
    },
    mounted() {
        if(this.$route.query && this.$route.query.session_id) {
            this.session_id = this.$route.query.session_id
            this.readonly = true
            this.queryAIHistory(()=>{},false)
        }else {
            this.querySession(null)
        }

        var audio = new Audio()
        audio.addEventListener('ended', function () {
            this.curPlaySrc = ''
        }, false)
        this.myAudio = audio

    },
    methods: {
        exitPractise() {
            const that = this
            that.$confirm({
                centered: true,
                title: '提示',
                content: '是否确定结束当前训练？',
                okText: '结束训练',
                okType: 'primary',
                cancelText: '取消',
                onOk() {
                    that.endAI(() => {
                        that.readonly = true
                        that.practice_result_mode = '1'
                    })
                },
                onCancel() {}
            })
        },
        closePracticeResult() {
            const that = this
            that.practice_result_mode = undefined
        },
        onPlay(src) {
            const that = this
            if (that.curPlaySrc){
                if (that.curPlaySrc == src){
                    that.curPlaySrc = ''
                    that.myAudio.pause()
                }else  {
                    that.curPlaySrc = ''
                    that.myAudio.pause()
                    setTimeout(function(){
                        that.playAudio(src)
                    },200)
                }
            }else {
                that.playAudio(src)
            }
        },
        playAudio(src) {
            const that = this
            if (!src || src == '') {
                return
            }
            //如果当前没有播放，则立即播放
            if (!that.curPlaySrc){
                that.curPlaySrc = src
                that.myAudio.src = src
                that.myAudio.play()
            }
            //否则，进入等待播放的队列
            else  {
                that.curPlayQueue.push(src)
            }
        },
        querySession(session_id) {
            const that = this
            doQuery({
                query_code: 'CCAIS0',
                param_str3: session_id || '',
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                    //关闭新的会话后再启动
                    that.session_id = res.data[0].session_id
                    that.session_status = res.data[0].session_status
                    //询问是否关闭还是继续
                    if (that.session_status == '1') {
                        that.$confirm({
                            centered: true,
                            title: '提示',
                            content: '您有未结束的AI训练会话，是否继续该训练？',
                            okText: '继续训练',
                            okType: 'primary',
                            cancelText: '新训练',
                            onOk() {
                                that.queryAIHistory()
                            },
                            onCancel() {
                                //开启新的训练
                                that.endAI(
                                    function() {
                                        that.startAI()
                                    }
                                )
                            }
                        })
                    }else {
                        that.queryAIHistory()
                    }
                }else {
                    //启动新的会话
                    that.startAI()
                }
            })
        },
        // 查询一个已有的AI会话
        queryAIHistory(func,toBottom = true) {
            const that = this
            doQuery({
                query_code: 'CCAIS1',
                param_str2: that.session_id,
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                    res.data.forEach(function(item, index) {
                        if (item.action_text.indexOf('启动陪练') != 0) {
                            //如果是：AI回复
                            if (item.action == 'reply') {
                                let action_text_obj = JSON.parse(item.action_text)
                                for (let i = 0; i < action_text_obj.length; i++) {
                                    if (action_text_obj[i].type != 'ignore' && !(action_text_obj[i].type == 'command' && action_text_obj[i].action == 'recall')) {
                                        that.chat_data.push({
                                            direction: 'l',
                                            msg: action_text_obj[i].text,
                                            msgtime: item.action_time,
                                            voice: action_text_obj[i].fileUrl
                                        })
                                    }
                                }
                            }
                            //如果是：客户回复
                            if (item.action == 'query') {
                                that.chat_data.push({
                                    direction: 'r',
                                    msg: item.action_text,
                                    msgtime: item.action_time
                                })
                            }
                        }
                    })
                    if(toBottom) {
                        setTimeout(()=>{
                            var ele = document.getElementById('chat-body')
                            ele.scrollTop = ele.scrollHeight
                        },200)
                    }
                    if(func) {
                        func()
                    }
                }else {
                    that.chat_data = null
                    that.$message.error('查询会话历史失败')
                }
            })

        },
        // 获取acct_id
        getAcctId() {
            const that = this
            return new Promise((resolve,reject) => {
                if(that.acct_id) {
                    resolve(that.acct_id)
                }else {
                    doQuery({
                        query_code: 'LNACCT',
                        page: 1,
                        limit: 1
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                            that.acct_id = res.data[0].acct_id
                            resolve(that.acct_id)
                        }
                    })
                }
            })
        },
        startAI(func) {
            const that = this
            that.$message.loading('开始启动AI对话')
            that.getAcctId()
                .then(acctId => {
                    startAI({
                        key: 'b290d717-74b5-41e8-a080-ed6a06b065a0',
                        staffAcctId: acctId,
                        staffId: that.staffId,
                        query: '启动陪练' + that.scene_id,
                        sceneId: that.scene_id
                    }).then(res => {
                        if(res.errcode == 0) {
                            //设置session
                            that.session_id = res.data.cmy_memo.session_id
                            that.session_status = '1'
                            //初始欢迎消息
                            that.chat_data.push({
                                direction: 'l',
                                msg: res.data.cmy_say[0].text,
                                msgtime: dateUtils.getNow(),
                                voice: res.data.cmy_say[0].fileUrl
                            })
                            //回调
                            if (func) {
                                func()
                            }
                        }else {
                            that.$message.error('启动训练会话失败')
                        }
                    })
                })
        },
        callAI(text) {
            const that = this
            that.getAcctId()
                .then(acctId => {
                    callAI({
                        key: 'b290d717-74b5-41e8-a080-ed6a06b065a0',
                        staffAcctId: acctId,
                        staffId: that.staffId,
                        sessionId: that.session_id,
                        query: text
                    }).then(res => {
                        that.query = null
                        if (res.errcode == 0) {
                            //设置ai回复消息
                            if (res.data.cmy_say && res.data.cmy_say.length > 0) {
                                res.data.cmy_say.forEach((item, index) => {
                                    //如果回复记录标志为可以忽略，则不处理
                                    if (item.type != 'ignore' && !(item.type == 'command' && item.action == 'recall')) {
                                        if (that.chat_data &&
                                            that.chat_data.length > 0 &&
                                            that.chat_data[that.chat_data.length - 1].msg == 'waiting') {
                                            that.$set(that.chat_data, that.chat_data.length - 1, {
                                                direction: 'l',
                                                msg: item.text,
                                                msgtime: dateUtils.getNow(),
                                                voice: item.fileUrl
                                            })
                                        }else {
                                            that.chat_data.push({
                                                direction: 'l',
                                                msg: item.text,
                                                msgtime: dateUtils.getNow(),
                                                voice: item.fileUrl
                                            })
                                        }

                                        setTimeout(()=>{
                                            var ele = document.getElementById('chat-body')
                                            ele.scrollTop = ele.scrollHeight
                                        },200)
                                    }
                                    //如果为命令
                                    if (item.type == 'command') {
                                        //再见
                                        if (item.action == 'bye') {
                                            that.endAI(() => {
                                                that.readonly = true
                                                that.practice_result_mode = '1'
                                            })
                                        }
                                        //recall
                                        if (item.action == 'recall'){
                                            that.callAI(item.text)
                                        }
                                    }

                                    //如果包含评分部分，则记录
                                    if (item.score != undefined && item.seqno != undefined) {
                                        that.scoreAI(item.score, item.seqno, item.answertotal)
                                    }
                                })
                            }else {
                                //再来一次
                                setTimeout(function(){
                                    that.callAI(text)
                                },500)
                            }
                        }
                    })
                })
        },
        endAI(func) {
            const that = this
            that.$message.loading('正在结束')
            that.getAcctId()
                .then(acctId => {
                    endAI({
                        key: 'b290d717-74b5-41e8-a080-ed6a06b065a0',
                        staffAcctId: acctId,
                        staffId: that.staffId,
                        sessionId: that.session_id,
                        query: 'end'
                    }).then(res => {
                        if (res.errcode == 0) {
                            that.$message.success('关闭训练会话成功')
                            that.session_status = 'e'
                            if (func) {
                                func()
                            }
                        } else {
                            that.$message.error('关闭训练会话失败')
                        }
                    })
                })
        },
        scoreAI(score, seqno, answer) {
            const that = this
            doAction({
                action_code: 'CCAI0',
                param_str2: that.session_id,
                param_str3: that.scene_id,
                param_int1: seqno,
                param_str5: answer,
                param_str4: score
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK ) {

                }
            })
        },
        sendQuery(text) {
            const that = this
            var msg = text || that.query
            if(!msg || util.trim(msg) == ''){
                that.$message.warn('说点啥吧~')
                return
            }

            //我说的话
            that.chat_data.push({
                direction: 'r',
                msg: msg,
                msgtime: dateUtils.getNow()
            })
            setTimeout(()=>{
                var ele = document.getElementById('chat-body')
                ele.scrollTop = ele.scrollHeight
                that.query = null
            },50)
            that.chat_data.push({
                direction: 'l',
                msg: 'waiting'
            })
            setTimeout(()=>{
                var ele = document.getElementById('chat-body')
                ele.scrollTop = ele.scrollHeight
            },50)
            //获取ai返回
            that.callAI(msg)
        },
        tts(msg,func) {
          const that = this
          tts({
              key: 'b290d717-74b5-41e8-a080-ed6a06b065a0',
              text: msg
          }).then(res => {
              if (res.errcode >= that.$consts.biz.OK) {
                  if (func) {
                      func(res.data)
                  }
              }
          })
        },

        stopAudio() {
            const that = this
            that.myAudio.pause()
            that.curPlaySrc = ''
        },

    }
}
