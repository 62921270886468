import { microRequest } from '@/utils/request'
import consts from '@/constants'

export function startAI (params) {
    return microRequest({
        url: consts.url.AI_START,
        method: 'post',
        data: params
    })
}

export function callAI (params) {
    return microRequest({
        url: consts.url.AI_CALL,
        method: 'post',
        data: params
    })
}

export function endAI (params) {
    return microRequest({
        url: consts.url.AI_END,
        method: 'post',
        data: params
    })
}

export function tts (params) {
    return microRequest({
        url: consts.url.AI_TTS,
        method: 'post',
        data: params
    })
}
